<template>
  <div class="otpSection">
    <div class="container">
      <div class="row h-100">
        <div class="col-12 col-sm-10 mx-auto">
          <div class="otpPanel">
            <div class="imgContainer text-center my-3">
              <img src="../../assets/img/logo.png" alt="" />
            </div>
            <h1 class="text-center mb-sm-4 mt-3">OTP</h1>
            <span class="text-muted mt-sm-4">
              Confirm your email and we'll send the instructions.
            </span>
            <span class="text-muted mt-2 mt-sm-3"
              >Enter the OTP you received</span
            >
            <div
              class="otpFields w-100 d-flex justify-content-evenly my-4 mx-auto"
            >
              <b-form-input
                aria-label="otp1"
                maxlength="1"
                type="tel"
                autofocus
                v-model="otp1"
                @keyup="enterOTP($event)"
              ></b-form-input>
              <b-form-input
                aria-label="otp2"
                maxlength="1"
                type="tel"
                v-model="otp2"
                @keyup="enterOTP($event)"
              ></b-form-input>
              <b-form-input
                aria-label="otp3"
                maxlength="1"
                type="tel"
                v-model="otp3"
                @keyup="enterOTP($event)"
              ></b-form-input>
              <b-form-input
                aria-label="otp4"
                maxlength="1"
                type="tel"
                v-model="otp4"
                @keyup="enterOTP($event)"
              ></b-form-input>
              <b-form-input
                aria-label="otp5"
                maxlength="1"
                type="tel"
                v-model="otp5"
                @keyup="enterOTP($event)"
              ></b-form-input>
              <b-form-input
                aria-label="otp6"
                maxlength="1"
                type="tel"
                v-model="otp6"
                @keyup="enterOTP($event)"
              ></b-form-input>
            </div>
            <span class="text-muted mb-0"> Didn't receive OTP? </span>
            <span class="text-muted mt-0 mb-3"
              >Request a new one in {{ countDown }} seconds</span
            >
            <div class="resendOTP w-100 text-center">
              <a
                href="#"
                class="my-3 disabled"
                id="resendBtn"
                @click="resendOTP"
                >Resend OTP</a
              >
            </div>

            <div class="verifyBtnContainer w-100 text-center my-4">
              <div
                v-if="
                  this.otp1 !== '' &&
                    this.otp2 !== '' &&
                    this.otp3 !== '' &&
                    this.otp4 !== '' &&
                    this.otp5 !== '' &&
                    this.otp6 !== ''
                "
                class="verifyBtn btn btn-primary"
                @click="verifyOTP"
              >
                Verify
              </div>
              <div v-else class="verifyBtn btn btn-primary disabled">
                Verify
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      countDown: 30,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    };
  },
  mounted() {
    var twilioId = this.$store.getters.getTwilioId;
    console.log(twilioId);
  },
  created() {
    var userData = this.$store.getters.data;
    console.log(userData);

    if (this.$route.params.path == "profile") {
      console.log("from profile page");
    }
  },
  methods: {
    enterOTP(e) {
      var target = e.srcElement || e.target;
      // console.log(target.value)
      if (isNaN(target.value)) {
        target.value = "";
        return;
      }

      var maxLength = parseInt(target.attributes["maxlength"].value, 10);
      var myLength = target.value.length;
      if (myLength >= maxLength) {
        var next = target;
        while ((next = next.nextElementSibling)) {
          if (next == null) break;
          if (next.tagName.toLowerCase() === "input") {
            next.focus();

            break;
          }
        }
      }
      // Move to previous field if empty (user pressed backspace)
      else if (myLength === 0) {
        var previous = target;
        while ((previous = previous.previousElementSibling)) {
          if (previous == null) break;
          if (previous.tagName.toLowerCase() === "input") {
            previous.focus();

            break;
          }
        }
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        document.getElementById("resendBtn").classList.remove("disabled");
      }
    },
    resendOTP() {
      var twilioId = this.$store.getters.getTwilioId;
      document.getElementById("resendBtn").classList.add("disabled");
      this.countDown = 30;
      this.countDownTimer();
      var data2 = new FormData();
      let command2 = `curl "https://api.authy.com/protected/json/sms/${twilioId}?force=true" -H "X-Authy-API-Key: ORdNXMXiNsABeKDoid1jvjCw1pWHOmDi"`;

      data2.append("command", command2);

      var config2 = {
        method: "post",
        url: "https://api.vaxtraxglobal.com/api/v1/twilio",
        data: data2,
      };

      axios(config2)
        .then(({ data }) => {})
        .catch(function(error) {
          console.log(error);
        });
      // alert("OTP Sent");
    },
    verifyOTP() {
      if ((this.$route.params.path = "profile")) {
        var number = this.$route.params.number;
        var self = this;
        var axios = require("axios");
        let otpProfile =
          self.otp1 + self.otp2 + self.otp3 + self.otp4 + self.otp5 + self.otp6;
        var data = new FormData();
        var twilioId = self.$store.getters.getTwilioId;
        var command3 = `curl "https://api.authy.com/protected/json/verify/${otpProfile}/${twilioId}" -H "X-Authy-API-Key: ORdNXMXiNsABeKDoid1jvjCw1pWHOmDi"`;

        data.append("command", command3);

        var config = {
          method: "post",
          url: "https://api.vaxtraxglobal.com/api/v1/twilio",
          data: data,
        };

        axios(config)
          .then(function(response) {
            console.log(JSON.stringify(response.data));

            var storeData = self.$store.getters.getData;
            var updateData = {
              email: storeData.email,
              id: storeData.id,
              password: storeData.password,
              twilioUserId: storeData.twilioUserId,
              mobileNo: number,
            };
            axios
              .put("https://api.vaxtraxglobal.com/api/v1/user", updateData, {
                Headers: {
                  role: "user",
                },
              })
              .then((data) => {
                console.log(data);
                self.$router.push("/dashboard");
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch(function(error) {
            console.log(error);
          });
      } else if ((this.$route.params.path = "signup")) {
        var self = this;

        var axios = require("axios");
        let otp =
          self.otp1 + self.otp2 + self.otp3 + self.otp4 + self.otp5 + self.otp6;
        var data = new FormData();
        var twilioId = self.$store.getters.getTwilioId;
        var command3 = `curl "https://api.authy.com/protected/json/verify/${otp}/${twilioId}" -H "X-Authy-API-Key: ORdNXMXiNsABeKDoid1jvjCw1pWHOmDi"`;

        data.append("command", command3);

        var config = {
          method: "post",
          url: "https://api.vaxtraxglobal.com/api/v1/twilio",

          data: data,
        };
        console.log(otp);
        axios(config)
          .then(function(response) {
            console.log(JSON.stringify(response.data));

            self.$router.push("/signup");
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.countDownTimer();
  },
};
</script>

<style lang="scss" scoped>
.otpSection {
  min-height: 100vh;
  width: 100%;
  background: #005eaa33;
  //   overflow:hidden;

  .row {
    min-height: 100vh;
    padding: 5vh 0;

    .otpPanel {
      background: #fff;
      height: auto;
      width: 100%;
      background: #fff;
      min-height: 450px;
      margin: 0 auto;
      padding: 10px 20px;
      border-radius: 40px;
      border: 1px solid #000;

      .otpFields {
        max-width: 400px;
      }

      img {
        height: 50px;
        margin: 0 auto;
      }

      > h1 {
        font-weight: 800 !important;
      }

      > span {
        display: block;
        text-align: center;
        font-size: 1.2rem;
        width: 40%;
        min-width: 250px;
        margin: 0 auto;
      }

      input {
        display: inline-block;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin: 0 8px;
        box-shadow: 0 0 5px rgb(231, 230, 230);
        font-size: 30px;
        text-align: center;
        caret-color: transparent;

        @media (max-width: 500px) {
          width: 40px;
          height: 40px;
          font-size: 25px;
          margin: 0 4px;
        }

        &:focus {
          border: 1px solid rgb(175, 196, 207);
          box-shadow: 0 0 5px rgb(157, 198, 221);
        }
      }

      .resendOTP {
        > a {
          font-weight: bolder;
          font-size: 1.2rem;
          text-decoration: underline;
        }
        .disabled {
          pointer-events: none;
          cursor: default;
          color: rgb(151, 151, 151);
        }
      }

      .verifyBtn {
        width: 100%;
        max-width: 400px;
        border-radius: 40px;
        padding: 10px;
        font-size: 1.2rem;
      }
    }
  }
}
</style>
